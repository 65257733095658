$background-color: #13172c;
$body-color: #2a2d3f;
$header-color: #1F2337;
$text-color: #fff;
$text-color-faded: rgba($text-color, 0.5);
$topic-text-color: rgba($text-color, 0.87);
$active-color: #7ACAFF;
$border-color: #414455;

$nissan-logo: '../images/nissan_logo_white.svg';

@media (prefers-color-scheme: dark) {
  *::-webkit-scrollbar {
    border-color: $background-color;
  }

  *::-webkit-scrollbar-track {
    background: $body-color;
    border-color: $body-color;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $background-color;
    border-color: $background-color;
    border-radius: 12px;
  }

  div.container {
    background-color: $background-color;

    .Vehicles {
      background-color: $background-color;

      .screen-head {
        background-color: $header-color;
      }

      .screen-title {
        color: #fff;
      }

      .topmenu-link {
        color: #fff;
      }

      .VehicleYearSelector {
        background-color: $background-color;
      }

      .VehicleList {
        background-color: $background-color;

        h2 {
          color: #fff;
        }

        .name {
          color: #fff;
        }
      }

      .VehicleYearSelector {
        &__label {
          color: $text-color;
        }

        .wrapper > a {
          background: $body-color;
          color: $text-color;

          &::after {
            color: $text-color;
          }

          &.current {
            background: $active-color;
            color: #000;
          }
        }
      }

      .VehicleModelSelector {
        background: $body-color;
        color: $text-color;

        .model-container {
          .model-group {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          }
        }

        .icon.icon-arrow-left {
          .stroke {
            stroke: $active-color;
          }
        }

        .close:before,
        .close:after {
          background: #fff;
        }
      }

      .VehicleGroupSelector {
        .group {
          background: $body-color;

          & > a {
            color: $text-color;
          }
        }

        .group-inner {
          background-color: $background-color;
        }
      }

      .VehicleYearSelector .slick-list:after {
        display: none;
      }
    }

    .Loader.inverse {
      background: $background-color;

      .status {
        color: #fff;
      }

      .loader-default > div {
        background-color: $active-color;
      }
    }

    .Guide {
      background-color: $background-color;

      .screen-head {
        .screen-back > * {
          color: $text-color;

          .icon.icon-arrow-back .fill {
            fill: #fff;
          }
        }

        .screen-title {
          color: $text-color;
        }

        .icon.icon-search {
          .fill {
            fill: #fff;
          }
        }

        .topmenu-link {
          color: #fff;
        }
      }

      .NavBar {
        background-color: $background-color;

        .nav-link {
          color: $text-color;
        }
        .nav-link.active {
          color: $active-color;
          box-shadow: inset 0 -3px 0px -1px $active-color;
        }
      }

      .topics {
        h2 {
          color: #fff;
        }
      }

      .Explore {
        background-color: $background-color;

        .visual-search {
          .hot-spot {
            background: $active-color
          }

          .image-map-container .image-map-selector {
            button {
              background: $body-color;
              border-top: 1px solid rgba(255, 255, 255, 0.1);
              border-right: 1px solid rgba(255, 255, 255, 0.1);

              .button-name {
                color: $active-color;
              }

              .icon {
                .fill {
                  fill: $active-color;
                }

                .stroke {
                  stroke: $active-color;
                }
              }

              &.active {
                background: $active-color;
                border-top: 1px solid $active-color;
                border-right: 1px solid $active-color;

                .button-name {
                  color: #000;
                }

                .icon {
                  .fill {
                    fill: #000;
                  }
                  .stroke {
                    stroke: #000;
                  }
                }
              }
            }
          }
        }
      }

      .TopicsList {
        background: $background-color;

        &__match .Loader {
          background: transparent;
        }

        &.publications {
          li .item-iconbar .icon .fill {
            fill: $active-color;
          }

          li .item-iconbar .icon .fill-inverted {
            fill: $body-color;
          }
        }

        li {
          .item-inner {
            background: $background-color;
          }

          .item-title {
            color: $text-color;

            .CopyLinkBtn path {
              fill: #fff;
            }
          }

          .item-iconbar {
            .icon {
              .fill {
                fill: #737582;
              }

              &.icon-arrow-left .stroke {
                stroke: $active-color;
              }
            }
          }
        }
      }

      .Topics {
        .screen-container {
          background: $background-color;
        }

        .topics-breadcrumb {
          background: $background-color;
        }

        .topics-breadcrumb li {
          color: #fff;
        }

        .topic-title {
          color: #fff;
        }

        .topics-subtitle {
          color: $text-color;
          box-shadow: none;
        }

        .topic-content {
          li,
          strong,
          .note * {
            color: $topic-text-color;
          }

          p,
          .p {
            color: $topic-text-color;

            &.grid a {
              background: $body-color;
            }
          }
        }

        .video-container .video-markers-container {
          .video-markers {
            background-color: transparent;

            button {
              color: $topic-text-color;
            }
          }
        }

        .SearchInput {
          background: $body-color;

          input {
            color: $text-color;

            &::placeholder {
              color: $text-color-faded;
            }
          }

          .icon.icon-cancel .fill {
            fill: #fff;
          }

          .icon.icon-search path {
            stroke: #fff;
          }

          & + .topics-subtitle hr {
            background: rgba(255, 255, 255, 0.15);
          }
        }

        .SortSelector {
          &__label {
            color: $text-color;
          }

          &__btn {
            color: $active-color;
          }

          .Menu {
            background: $background-color;

            .menu-item {
              color: $text-color;
            }
          }
        }

        .PdfMatches {
          &__header {
            box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.15);
          }

          &__search-result-page {
            color: $text-color-faded;
          }

          &__search-result-match {
            color: $topic-text-color;
          }
        }

        .PdfViewer {
          .SearchResults {
            background: $body-color;

            &__item {
              border: 1px solid rgba(255, 255, 255, 0.15);
            }

            &__item--selected {
              border: 1px solid $active-color;
            }

            &__item-content {
              color: $text-color;
            }

            &__item-page {
              color: $text-color-faded;
            }

            .CloseBtn .fill {
              fill: #fff;
            }
          }

          .PdfPreview {
            background: $body-color;

            .Loader {
              background: transparent;
            }
          }
        }

      }
    }

    .screen-logo:after,
    .terms-logo:after {
      background-image: url($nissan-logo);
    }

    .screen-head {
      background-color: $header-color !important;

      .screen-topmenu .icon.icon-dots .fill {
        fill: #fff;
      }

      .screen-title {
        color: #fff !important;
      }

      .screen-back .icon.icon-arrow-left g {
        stroke: #fff;
      }
    }

    .NavBar {
      border-top-color: $border-color;
      border-bottom-color: $border-color;
    }

    .topics-breadcrumb {
      box-shadow: inset 0 -1px $border-color;
    }

    .TopicsList.publications li .item-inner {
      @media screen and (min-device-width: 320px) and (max-device-width: 769px) {
        background-color: $header-color;
      }
    }

    .ErrorMessage {
      h1,
      .message {
        color: $text-color;
      }
    }
  }
}
